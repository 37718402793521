import React from 'react'

const Input = (props)=> {
    return (
        <input name={props.name} onChange={props.onChange} type={props.type} placeholder={props.label} style={inputStyle} />
    )
}


const inputStyle = {
    color: "#000",
  backgroundColor: "#fff",
  fontFamily: "bahij",
  border: "1px solid rgba(0,0,0,0.1)",
  borderRadius: '10px',
  padding: "9px 20px",
  margin: "5px 10px",
  fontSize: "17px",
  width: "80%",
}

export default Input;
