import React, { useState } from "react";
import Layout from "../components/layout";
import Input from "../components/input";
import MyButton from "../components/button";
import order from "../assets/images/order.png";
import postData from "../data/postData";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import background from "../assets/images/backpat.png";
import Card from "../components/card";
import { Formik } from "formik";
import { Button } from "@mui/material";

const Form = () => {
  
  const results = useSelector((state) => state.questions.answers);
  const answers = results.map((answer) => {
    return answer.id;
  });


  return (
    <Layout>
      <div style={styles.body}>
        <Card>
          <h1 style={styles.title}>لحجز المنتج أدخل بيانات الطلب</h1>
          <img style={styles.image} src={order} alt="order" />
          <div style={styles.container}>
            <Formik
              initialValues={{
                name: "",
                phoneNumber: "",
                address: "",
                city: "",
                country: "",
                state: "",
                zipCode: "",
                addressLine2: "",
                comment: "",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.name) {
                  errors.name = "الاسم مطلوب";
                }
                if (!values.phoneNumber) {
                  errors.phoneNumber = "رقم الهاتف مطلوب";
                }
                if (!values.address) {
                  errors.address = "العنوان مطلوب";}
                if (!values.city) {
                  errors.city = "المدينة مطلوبة";
                }
                if (!values.country) {
                  errors.country = "الدولة مطلوبة";
                }
                if (!values.state) {
                  errors.state = "المحافظة مطلوبة";
                }
                if (!values.zipCode) {
                  errors.zipCode = "الرمز البريدي مطلوب";
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {     

                  postData(
                    {
                      name: values.name,
                      phone_number: values.phoneNumber,
                      country: values.country,
                      state: values.state,
                      city: values.city,
                      address: values.address,
                      zip_code: values.zipCode,
                      comment: values.comment,
                      address_line2: values.addressLine2,

                      answers: answers.toString() ?? "1000",
                    },
                    (response) => {
                      console.log(response.data);
                      if (response) {
                        console.log(response.data);
                        window.location.replace(`https://secure.vertex-colon.ca${response.data.pay_url}`);
                      //  navigate(response.data.pay_url);
                      }
                    }
                  );

                  setSubmitting(false);
                  
              
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <Input
                    type="text"
                    name="name"
                    label="اسمك الكامل"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  {errors.name && touched.name && errors.name}
                  <Input
                    type="number"
                    name="phoneNumber"
                    label="رقم الهاتف"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phoneNumber}
                  />
                  {errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
                  <Input
                    type="text"
                    name="country"
                    label="البلد"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.country}
                  />
                  {errors.country && touched.country && errors.country}
                  <Input
                    type="text"
                    name="state"
                    label="الولاية او المحافظة"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.state}
                  />
                  {errors.state && touched.state && errors.state}
                  <Input
                    type="text"
                    name="city"
                    label="المدينة"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                  />
                  {errors.city && touched.city && errors.city}
                  <Input
                    type="text"
                    name="address"
                    label="العنوان"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address}
                  />
                  {errors.address && touched.address && errors.address}
                  <Input
                    type="text"
                    name="addressLine2"
                    label="سطر العنوان الثاني"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.addressLine2}
                  />
                  <Input
                    type="text"
                    name="zipCode"
                    label="الرمز البريدي"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.zipCode}
                  />
                  {errors.zipCode && touched.zipCode && errors.zipCode}
                  <Input
                    type="textarea"
                    name="comment"
                    label="تعليقات اخرى"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.comment}
                  />
                  
                  <Button sx={styles.btnStyle} type="submit" disabled={isSubmitting}>
                    دفع
                  </Button>
                </form>
              )}
            </Formik>
          </div>
        </Card>
      </div>
    </Layout>
  );
};


const styles = {
  body: {
    fontFamily: "bahij",
    minHeight: "100vh",
    overflow: "hidden",
    width: "full",
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "20px",
  },
  container: {
    textAlign: "center",
    alignItems: "center",
    width: "full",
    marginTop: "10px",
  },
  image: {
    width: "100%",
    margin: "auto",
  },
  title: {
    fontSize: "21px",
    textAlign: "center",
    marginTop: "30px",
  },
  err: {
    color: "red",
    textAlign: "center",
  },
  phoneErr: {
    color: "red",
  },

  btnStyle: {
    color: "#fff",
  background: 'linear-gradient(90deg, #324193 0%, #325BA7 8%, #3177BB 18%, #318ACA 29%, #3196D3 39%, #319AD6 50%, #3194D1 59%, #3182C4 71%, #3265AE 86%, #324193 100%);',
  fontFamily: "bahij",
  borderRadius: 3,
  padding: "7px 20px",
  fontSize: "19px",
  margin: "5px 10px",
  width: "90%",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#000",
    boxShadow: "none",
  },
  }
};

export default Form;
