import axios from "axios";
import { stringify } from "qs";

const postData = (formData, callback) => {
  var data = stringify(formData);
  var config = {
    method: "post",
    url: "https://secure.vertex-colon.ca/api/orders/new",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
        callback(response)
    })
    .catch(function (error) {
        callback(error)
    });
};

export default postData;

/*
{
    name: "جبار",
    phone_number: "07810094883",
    city: "ميسان",
    address: "عواشة",
    answers: "9,11",
  }

  */
